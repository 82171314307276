import { Route, Redirect, Switch } from 'react-router-dom'
import React from 'react'

import Admin from './components/main'
import ApplicationsRoute from './routes/applications'
import { FlagAdmin } from './routes/applications/FlagAdmin'

export default function AdminRoute() {
  return (
    <Admin>
      <Switch>
        <Route component={ApplicationsRoute} path="/admin/applications" />
        <Route component={FlagAdmin} path="/admin/flags" />
        <Redirect to="/404" />
      </Switch>
    </Admin>
  )
}
