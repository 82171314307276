import { Dispatch } from 'react'

export interface Application {
  _id: string
  name: string
  slug: string
  flags: { [key: string]: boolean }
  updatedAt: Date
  createdAt: Date
}
export interface UseApplicationList {
  applications: Application[]
  error: Error
  filteredApplications: Application[]
  isLoading: boolean
  setFilter: Dispatch<string>
}
export interface UseApplicationListProps {
  region: string
  endpoints: { [region: string]: string }
}
export enum ExpiryDurationUnit {
  Minute = 'minutes',
  Hour = 'hours',
  Day = 'days',
  Week = 'weeks',
  Month = 'months',
}
