import React, { useState, useEffect, useMemo } from 'react'
import {
  Settings,
  Loader,
  Info,
  CircleCheck,
  ArrowDownAz,
  ArrowDown01,
  ArrowUp10,
} from 'lucide-react'
import { useSelector } from 'react-redux'
import { Flex, Block, Text } from 'components/common'
import Button from 'components/button-next'
import { InputText } from 'components/form'
import { useRequest } from 'components/useRequest'
import { TabGroup } from 'components/TabGroup'
import { Application } from './applications.types'

import useApplicationList from './helpers/use-fetch-applications'

interface FlagStats {
  name: string
  enabledCount: number
  percentage: number
  applications: Array<{ id: string; name: string; isEnabled: boolean }>
}

type Flags = Record<string, FlagStats>

const ProgressBar = ({ percentage, height = 12 }) => (
  <Block
    width="100%"
    height={height}
    borderRadius={6}
    backgroundColor="#e0e0e0"
    position="relative"
  >
    <Block
      position="absolute"
      top={0}
      left={0}
      height="100%"
      width={`${percentage}%`}
      backgroundColor={percentage < 100 ? '#10b981' : '#3b82f6'}
      borderRadius={6}
      transition="width 0.5s ease"
    />
  </Block>
)

export const FlagAdmin = () => {
  const { applications: allApplications, isLoading } = useApplicationList()
  const { request } = useRequest()
  const region = useSelector(state => state.app.region)

  const applications = useMemo(
    () =>
      allApplications.filter(
        app =>
          app.slug &&
          !(app.flags.inactive || app.name.toLowerCase().includes('inactive'))
      ),
    [allApplications]
  )

  const applicationCount = applications.length
  const [flags, setFlags] = useState<Flags>({})
  const [filterStatus, setFilterStatus] = useState('all')
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState<'Name' | 'Asc' | 'Desc'>('Name')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedFlag, setSelectedFlag] = useState(null)
  const [targetPercentage, setTargetPercentage] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [expandedStats, setExpandedStats] = useState({})

  const statusOptions = [
    { value: 'all', label: 'All Flags' },
    { value: 'partial', label: 'Partially Rolled Out' },
    { value: 'complete', label: 'Fully Rolled Out' },
    { value: 'none', label: 'Not Rolled Out' },
  ]

  // Calculate flag statistics from applications
  useEffect(() => {
    if (!applications || applications.length === 0) return

    const flagStats: Flags = {}

    applications.forEach(app => {
      if (!app.flags) {
        console.warn(`Application ${app.name} (${app.slug}) has no flags`)
        return
      }

      for (const flagName in app.flags) {
        const isEnabled = app.flags[flagName]
        if (!flagStats[flagName]) {
          flagStats[flagName] = {
            name: flagName,
            enabledCount: 0,
            percentage: 0,
            applications: [],
          }
        }

        if (isEnabled === true) {
          flagStats[flagName].enabledCount++
        }

        flagStats[flagName].applications.push({
          id: app._id,
          name: app.name,
          isEnabled,
        })
      }
    })

    // Calculate percentages
    Object.values(flagStats).forEach(flag => {
      flag.percentage =
        applicationCount > 0
          ? Math.floor((flag.enabledCount / applicationCount) * 100)
          : 0
    })

    setFlags(flagStats)
  }, [applications])

  const filteredFlags = useMemo(() => {
    const flagsArray = Object.values(flags)
    const flagsByStatus =
      filterStatus === 'all'
        ? flagsArray
        : flagsArray.filter(flag => {
            if (filterStatus === 'partial') {
              return flag.percentage > 0 && flag.percentage < 100
            } else if (filterStatus === 'complete') {
              return flag.percentage === 100
            } else if (filterStatus === 'none') {
              return flag.percentage === 0
            }
            return true
          })

    const flagsBySearch = flagsByStatus.filter(flag => {
      return flag.name.toLowerCase().includes(searchTerm.toLowerCase())
    })

    return flagsBySearch
  }, [flags, filterStatus, searchTerm])

  const handleOpenDialog = (flag: FlagStats) => {
    setSelectedFlag(flag)
    setTargetPercentage(flag.percentage)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedFlag(null)
    setSuccessMessage(null)
  }

  const calculateAffectedApps = (
    flag: FlagStats,
    targetPct: number
  ): number => {
    if (!flag) return 0

    const currentEnabledCount = flag.enabledCount
    const targetEnabledCount = Math.round(applicationCount * (targetPct / 100))

    return Math.abs(targetEnabledCount - currentEnabledCount)
  }

  const getAppsToUpdate = (
    flag: FlagStats,
    targetPct: number
  ): Application[] => {
    if (!flag) return []

    const currentEnabledCount = flag.enabledCount
    const targetEnabledCount = Math.round(applicationCount * (targetPct / 100))
    const difference = targetEnabledCount - currentEnabledCount

    if (difference === 0) return []

    // Need to enable more applications
    if (difference > 0) {
      const disabledApps = applications.filter(app => !app.flags[flag.name])
      // Randomly select the number we need to enable
      return disabledApps.slice(0, difference)
    }
    // Need to disable some applications
    else {
      const enabledApps = applications.filter(app => app.flags[flag.name])
      // Randomly select the number we need to disable
      return enabledApps.slice(0, Math.abs(difference))
    }
  }

  // Update applications to match target percentage
  const handleUpdateFlag = async () => {
    if (!selectedFlag || targetPercentage === selectedFlag.percentage) {
      return
    }

    setIsSubmitting(true)

    try {
      const appsToUpdate = getAppsToUpdate(selectedFlag, targetPercentage)
      const currentPercentage = selectedFlag.percentage
      const updatedFlagValue = targetPercentage > currentPercentage

      // Track success/failure for each app
      let successCount = 0
      let failCount = 0

      // Process each application update
      for (const app of appsToUpdate) {
        try {
          const path = `/applications/${app._id}`
          await request(
            path,
            {
              method: 'PUT',
              body: {
                flags: {
                  [selectedFlag.name]: updatedFlagValue,
                },
              },
            },
            { scopeByApplication: false }
          )

          successCount++
        } catch (err) {
          console.error(`Failed to update app ${app._id}:`, err)
          failCount++
        }
      }

      let newEnabledCount: number

      // If we had at least partial success, update the local state
      if (successCount > 0) {
        newEnabledCount = updatedFlagValue
          ? selectedFlag.enabledCount + successCount
          : selectedFlag.enabledCount - successCount

        const newPercentage = Math.floor(
          (newEnabledCount / applicationCount) * 100
        )

        setFlags(prevFlags => ({
          ...prevFlags,
          [selectedFlag.name]: {
            ...prevFlags[selectedFlag.name],
            enabledCount: newEnabledCount,
            percentage: newPercentage,
            // Update application status in the flag data
            applications: prevFlags[selectedFlag.name].applications.map(app => {
              const updatedApp = appsToUpdate.find(a => a._id === app.id)
              if (updatedApp) {
                return {
                  ...app,
                  isEnabled: updatedFlagValue,
                }
              }
              return app
            }),
          },
        }))
      }

      // Set success or partial success message
      if (failCount === 0) {
        setSuccessMessage(
          `Successfully updated ${selectedFlag.name} to approximately ${targetPercentage}% rollout. ${successCount} applications were affected.`
        )
      } else {
        setSuccessMessage(
          `Partially updated ${selectedFlag.name}. ${successCount} applications were updated, but ${failCount} failed.`
        )
      }

      console.debug(
        `Updated Flag:${selectedFlag.name} to ${targetPercentage}% rollout.`
      )
      console.debug(`Success count: ${successCount}`)
      console.debug(`Fail count: ${failCount}`)
      console.debug(`New enabled count: ${newEnabledCount}`)
      console.debug(
        'Applications Updated',
        appsToUpdate.map(app => app.name)
      )
    } catch (err) {
      console.error('Error updating flag:', err)
      setSuccessMessage(`Error: ${err.message}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  // Toggle expanded stats for a flag
  const toggleExpandStats = (flagName: string) => {
    setExpandedStats(prev => ({
      ...prev,
      [flagName]: !prev[flagName],
    }))
  }

  return (
    <Block padding="5em">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom="24px"
      >
        <Flex flexDirection="row" alignItems="flex-end" gap="1em">
          <Text fontSize="24px" fontWeight="600" margin={0}>
            Feature Flag Manager
          </Text>
          <Text margin={0} fontSize="18px" color="#6b7280">
            {region === 'us'
              ? '🇺🇲 US Region'
              : region === 'au'
              ? '🇦🇺 APAC Region'
              : region}
          </Text>
        </Flex>

        <Flex gap="1em">
          <TabGroup
            tabs={[
              {
                label: 'Name',
                onClick: () => setSortBy('Name'),
                Icon: ArrowDownAz,
              },
              {
                label: 'Desc',
                onClick: () => setSortBy('Desc'),
                Icon: ArrowUp10,
              },
              {
                label: 'Asc',
                onClick: () => setSortBy('Asc'),
                Icon: ArrowDown01,
              },
            ]}
            selectedTab={sortBy}
          />
          <select
            value={filterStatus}
            onChange={e => setFilterStatus(e.target.value)}
            style={{
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
              fontSize: '14px',
              color: '#24292f',
              padding: '6px 10px',
              border: '1px solid #d0d7de',
              borderRadius: '4px',
              backgroundColor: '#ffffff',
              cursor: 'pointer',
              minHeight: '32px',
              width: '100%',
              maxWidth: '300px',
            }}
          >
            {statusOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </Flex>
      </Flex>

      <InputText
        placeholder="Search flags or applications..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        marginBottom="24px"
      />

      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          padding="40px"
          backgroundColor="#f8f9fa"
          borderRadius="8px"
        >
          <Loader color="#999" size={32} className="spin" />
        </Flex>
      ) : filteredFlags.length === 0 ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          padding="40px"
          backgroundColor="#f8f9fa"
          borderRadius="8px"
        >
          <Text color="#6b7280">No feature flags found</Text>
        </Flex>
      ) : (
        <Block>
          {filteredFlags
            .sort((a, b) => {
              if (sortBy === 'Name') {
                return a.name.localeCompare(b.name)
              } else if (sortBy === 'Asc') {
                return (
                  a.percentage - b.percentage || a.name.localeCompare(b.name)
                )
              } else {
                return (
                  b.percentage - a.percentage || a.name.localeCompare(b.name)
                )
              }
            })
            .map(flag => (
              <Block
                key={flag.name}
                backgroundColor="#ffffff"
                borderRadius="8px"
                marginBottom="16px"
                padding="16px"
                boxShadow="0 1px 3px rgba(0,0,0,0.1)"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <Flex flexDirection="column">
                    <Text fontWeight="600" fontSize="16px">
                      {flag.name}
                    </Text>
                    <Text color="#6b7280" fontSize="14px">
                      {flag.enabledCount} of {applicationCount} applications
                    </Text>
                  </Flex>

                  <Flex alignItems="center">
                    <Block
                      cursor="pointer"
                      onClick={() => toggleExpandStats(flag.name)}
                      marginRight="1em"
                    >
                      <Info size={20} />
                    </Block>

                    <Button
                      disabled={flag.percentage === 100}
                      onClick={() => handleOpenDialog(flag)}
                    >
                      <Settings size={16} />
                      <Text marginLeft="4px">
                        {flag.percentage === 0 ? 'Enable' : 'Adjust'}
                      </Text>
                    </Button>
                  </Flex>
                </Flex>

                <Flex alignItems="center" marginTop="12px">
                  <Block flexGrow={1} marginRight="12px">
                    <ProgressBar percentage={flag.percentage} />
                  </Block>
                  <Text fontWeight="600" minWidth="40px" textAlign="right">
                    {flag.percentage}%
                  </Text>
                </Flex>

                {/* Expanded statistics */}
                {expandedStats[flag.name] && (
                  <Block
                    marginTop="16px"
                    padding="12px"
                    backgroundColor="#f8f9fa"
                    borderRadius="6px"
                  >
                    <Text fontWeight="600" marginBottom="8px">
                      Applications with this flag:
                    </Text>
                    <Block maxHeight="200px" overflow="auto">
                      {flag.applications
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(app => (
                          <Flex
                            key={app.id}
                            justifyContent="space-between"
                            padding="6px 0"
                            borderBottom="1px solid #eee"
                          >
                            <Text>{app.name}</Text>
                            <Text color={app.isEnabled ? '#10b981' : '#6b7280'}>
                              {app.isEnabled ? 'Enabled' : 'Disabled'}
                            </Text>
                          </Flex>
                        ))}
                    </Block>
                  </Block>
                )}
              </Block>
            ))}
        </Block>
      )}

      {/* Dialog for flag adjustment */}
      {dialogOpen && selectedFlag && (
        <Block
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0,0,0,0.5)"
          zIndex="1000"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Block
            width="90%"
            maxWidth="500px"
            backgroundColor="#ffffff"
            borderRadius="8px"
            padding="24px"
          >
            <Text fontSize="20px" fontWeight="600" margin="0 0 1em">
              Adjust Rollout for {selectedFlag.name}
            </Text>

            {successMessage ? (
              <Block
                padding="12px"
                backgroundColor="#ecfdf5"
                borderRadius="6px"
                marginBottom="16px"
              >
                <Flex alignItems="center" gap="1em">
                  <CircleCheck size={32} color="#10b981" />
                  <Text color="#10b981">{successMessage}</Text>
                </Flex>
              </Block>
            ) : (
              <>
                <Text marginBottom="2em">
                  Current rollout:{' '}
                  <Text inline fontWeight="600">
                    {selectedFlag.percentage}%
                  </Text>{' '}
                  of applications ({selectedFlag.enabledCount} /{' '}
                  {applicationCount})
                </Text>

                <Block marginBottom="24px">
                  <Text fontWeight={500} margin="0">
                    Adjust rollout percentage:
                  </Text>
                  <Flex alignItems="center">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      step="5"
                      value={targetPercentage}
                      onChange={e =>
                        setTargetPercentage(parseInt(e.target.value))
                      }
                    />
                    <Text fontWeight="600" minWidth="40px">
                      {targetPercentage}%
                    </Text>
                  </Flex>
                </Block>

                <Block
                  padding="1em"
                  backgroundColor="#f8f9fa"
                  borderRadius="6px"
                  marginBottom="24px"
                >
                  <Text fontWeight="600" margin="0 0 1em">
                    Impact of this change:
                  </Text>

                  <Text marginBottom="4px">
                    • {Math.round(applicationCount * (targetPercentage / 100))}{' '}
                    of {applicationCount} applications will have this flag
                    enabled
                  </Text>

                  <Text marginBottom="4px">
                    • {calculateAffectedApps(selectedFlag, targetPercentage)}{' '}
                    applications will be{' '}
                    {targetPercentage > selectedFlag.percentage
                      ? 'enabled'
                      : 'disabled'}
                  </Text>

                  <Text>
                    • New rollout percentage will be {targetPercentage}%
                  </Text>
                </Block>
              </>
            )}

            <Flex justifyContent="flex-end">
              <Button
                variant="outline"
                onClick={handleCloseDialog}
                marginRight="8px"
              >
                {successMessage ? 'Close' : 'Cancel'}
              </Button>

              {!successMessage && (
                <Button
                  primary
                  disabled={
                    targetPercentage === selectedFlag.percentage || isSubmitting
                  }
                  onClick={handleUpdateFlag}
                >
                  {isSubmitting ? (
                    <Flex alignItems="center" gap="0.5em">
                      <Loader size={16} className="spin" />
                      Updating
                    </Flex>
                  ) : targetPercentage > selectedFlag.percentage ? (
                    `Increase to ${targetPercentage}%`
                  ) : (
                    `Decrease to ${targetPercentage}%`
                  )}
                </Button>
              )}
            </Flex>
          </Block>
        </Block>
      )}
    </Block>
  )
}
