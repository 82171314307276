import React from 'react'
import colors from 'config/theme/colors'
import { Flex, Text } from 'components/common'

interface TabGroupProps {
  tabs: Array<{
    label: string
    onClick: () => void
    Icon: React.ReactNode
  }>
  selectedTab: string
}

export const TabGroup: React.FC<TabGroupProps> = ({ tabs, selectedTab }) => {
  return (
    <Flex
      backgroundColor={colors.gray.lightest}
      padding={2}
      borderRadius="0.5rem"
    >
      {tabs.map((tab, index) => {
        const Icon = tab.Icon
        const isSelected = tab.label === selectedTab
        return (
          <button
            key={index}
            onClick={tab.onClick}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 12px',
              borderRadius: '0.375rem',
              fontSize: '0.875rem',
              backgroundColor: isSelected ? '#fff' : 'transparent',
              color: isSelected ? '#111827' : '#4b5563',
              boxShadow: isSelected ? '0 1px 2px 0 rgba(0, 0, 0, 0.05)' : 'none',
              transition: 'all 200ms',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            <Icon style={{ 
              width: '14px', 
              height: '14px',
              marginRight: '6px'
            }} />
            <Text margin={0}>
              {tab.label}
            </Text>
          </button>
        )
      })}
    </Flex>
  )
}
